@font-face {
  font-family: "Light";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
  url("./fonts/Socake.ttf") format("truetype");
}
@font-face {
  font-family: "Medium";   /*Can be any text*/
  src: local("AirbnbCerealMedium"),
  url("./fonts/Socake.otf") format("woff");
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }

}
.navbar {
  display: flex;
  width: 100%;
  height: 100%;
  font-family: "Light";

}
.HomePage {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: dodgerblue;
}

.ImmagineHeader {
  display: flex;
  width: 100%;
  height: 100%;
}
.ImmagineHeader2 {
  display: flex;
  width: 100%;
  height: 100%;
}
.d-inline-block{
  margin-top: -5px;
  width: 50px;
  height: 50px;
}
.titolo{
  margin-top: 8px;
  color: black;
  font-size: 30px;
}
.titolo:hover{
  color:black;
}
.nav-item1{
  display: flex;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  justify-content: center;
}
.nav-item2{
  display: flex;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  justify-content: center;
}
.nav-item3{
  display: flex;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  justify-content: center;
}
.scrittesotto {
  font-size: small;
  padding-left: 15%;
  padding-top: 100%;
}
.scrittecomponi {
  display: flex;
  font-size: 20px;
  margin-top: 15px;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  color: white;
  font-family: "Light";

}
.scrittecomponi2 {
  display: flex;
  font-size: 20px;
  margin-top: 15px;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  color: white;
  font-family: "Light";
}

.ImmagineStoria {
  display: flex;
  width: 300px;
  height: 250px;
  margin-top: 20px;
  margin-left: 35px;
}
.lanostrastoria{
  display: flex;
  font-size: 30px;
  margin-top: 10px;
  justify-content: center;
  width: 100%;
  font-family: "Light";
  color: blueviolet;
}

.scrittaPromo {
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 30px;
  margin-bottom: 50px;
  justify-content: center;
  font-weight: bold;
  font-family: "Light";

}
.form-text {
  display: flex;
  width: 60%;
  height: 30%;
  margin-top: -120px ;
  margin-left: auto;
  margin-right: auto;
  justify-items: center;
  font-size: larger;
  font-family: "Light";

}
.btn-icon{
  display: flex;
  width: 30%;
  height: 17%;
  justify-content: center;
  background-color: dodgerblue;
  font-size: 30px;
  border-radius: 10px;
  border-width: 2px;
  margin-top: 30px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  font-family: "Light";
}
.download {
  display: flex;
  width: 50%;
  height: 100%;
  margin: auto;
  justify-content: center;
  background-color: coral;
  text-decoration: none;
  font-size: larger;
  border-radius: 10px;
  border-width: 2px;
  font-family: "Light";
}
a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

.accordion-button {
  background-color:  greenyellow;
  margin-top: -30px;
  font-family: "Light";
  font-size: 15px;
  font-weight: bold;
  color: #282c34;
}


.panesopra {
  display: flex;
  font-size: larger;
  margin-top: 75px;
  justify-content: center;
  width: 100%;
  height: 100%;
  color:white;
  font-family: "Light";
  font-weight: bolder;
  background-color: dodgerblue;
}
.verdura {
  display: flex;
  font-size: larger;
  margin-bottom: 100px;
  margin-top: -50px;
  justify-content: center;
  color: white;
  font-weight: bold;
  width: 100%;
  height: 100%;
  font-family: "Light";
  background-color: dodgerblue;
}
.cheese {
  display: flex;
  font-size: larger;
  margin-bottom: 40px;
  margin-top: -65px;
  justify-content: center;
  width: 100%;
  height: 100%;
  color:white;
  font-weight: bold;
  font-family: "Light";
  background-color: dodgerblue;
}
.burger {
  display: flex;
  font-size: larger;
  margin-bottom: 100px;
  margin-top: -60px;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: white;
  font-weight: bold;
  font-family: "Light";
  background-color: dodgerblue;
}


.lanostrastoria2{
  display: flex;
  margin-top: 10px;
  justify-content: center;
  width: 100%;
  font-family: American Typewriter;


}
.card{
  width: 100%;
  height: 100%;
  border-color: #282c34;
  border-width: 2px;
  background-color: #fcdc38;
  margin: auto;
  margin-top: -10px;
}
.filtro {
  display: flex;
  width: 100%;
  height: 100%;
}
.filtro2 {
  display: flex;
  width: 70%;
  height: 100%;
  margin-left: 40px;
  margin-top: -110px;
}
.filtro3 {
  display: flex;
  width: 80%;
  height: 100%;
  margin-top: -15px;
  margin-left: 20px;
}
.accordion-body{
  background-color: #ffc700;
}
.componi {
  background-color: dodgerblue;
}
.azzurro {
  background-color: dodgerblue;
}
.card3{
  margin-left: 25px;
  margin-right: 25px;
}

